<template>
  <div v-if="community" class="flex justify-center min-h-screen bg-gradient-to-br from-amber-700 to-gray-900">
    <div class="w-full max-w-2xl bg-gray-900 overflow-hidden shadow-2xl">
      <!-- Header Image -->
      <div class="relative h-48 bg-gradient-to-r from-yellow-400 via-pink-500 to-blue-500">
        <img v-if="community.bannerUrl" :src="community.bannerUrl" alt="Community Banner"
          class="w-full h-full object-cover" />
        <!-- share button -->
        <button @click="handleShare" class="absolute top-4 right-4 text-white">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z" />
          </svg>
        </button>
      </div>

      <!-- Profile Info -->
      <div class="relative flex flex-col items-center -mt-20 mb-4 mx-4">
        <img v-if="community.imageUrl" :src="getImageUrl(community.imageUrl)" alt="Community Logo"
          class="w-32 h-32 rounded-full border-4 border-gray-900" />
        <h2 class="mt-2 text-2xl font-semibold text-white">{{ community.name }}</h2>
        <h3 class="text-sm text-gray-400">{{ community.description }}</h3>
        <div class="flex space-x-2 mt-2">
          <!-- Icons -->
          <IconLink v-if="community.linkedinUrl" :href="community.linkedinUrl" icon="lucide:linkedin" size="24"
            class="text-blue-400 hover:text-blue-500" />
          <IconLink v-if="community.twitterUrl" :href="community.twitterUrl" icon="lucide:twitter" size="24"
            class="text-blue-400 hover:text-blue-500" />
          <IconLink v-if="community.instagramUrl" :href="community.instagramUrl" icon="lucide:instagram" size="24"
            class="text-pink-400 hover:text-pink-500" />
          <IconLink v-if="community.tiktokUrl" :href="community.tiktokUrl" icon="lucide:brand-tiktok" size="24"
            class="text-gray-400 hover:text-gray-500" />
          <IconLink v-if="community.discordUrl" :href="community.discordUrl" icon="lucide:message-circle" size="24"
            class="text-indigo-400 hover:text-indigo-500" />
          <IconLink v-if="community.telegramUrl" :href="community.telegramUrl" icon="lucide:send" size="24"
            class="text-sky-400 hover:text-sky-500" />
          <IconLink v-if="community.websiteUrl" :href="community.websiteUrl" icon="lucide:globe" size="24"
            class="text-emerald-400 hover:text-emerald-500" />
        </div>
      </div>

      <!-- Join Community CTA -->
      <div class="px-6 py-4 mb-4">
        <button @click="handleJoinCommunity" :disabled="isJoined"
          class="w-full font-semibold py-3 px-6 rounded-lg transition-all duration-200 shadow-lg" :class="[
            isJoined
              ? 'bg-gray-700 text-gray-300 cursor-default'
              : 'bg-gradient-to-r from-amber-500 to-amber-700 text-white hover:from-amber-600 hover:to-amber-800'
          ]">
          {{ isJoined ? 'Joined Community' : 'Join Community' }}
        </button>
      </div>

      <!-- Add Authentication Dialog -->
      <Dialog v-model:visible="isDialogVisible" modal :style="{ width: '400px' }" :closable="true"
        :dismissableMask="true" header="Sign in to Join Community">
        <div class="p-4">
          <p class="mb-4 text-gray-600">
            Please sign in or create an account to join this community.
          </p>
          <div class="space-y-3">
            <NuxtLink :to="{ path: '/sign-in', query: { redirect: currentPath } }" @click="closeDialog"
              class="w-full bg-white text-gray-900 py-2 rounded-lg font-semibold hover:bg-gray-200 transition-colors flex items-center justify-center border border-gray-300">
              Sign In
            </NuxtLink>
            <NuxtLink :to="{ path: '/sign-up', query: { redirect: currentPath } }" @click="closeDialog"
              class="w-full bg-gray-700 text-white py-2 rounded-lg font-semibold hover:bg-gray-600 transition-colors flex items-center justify-center">
              Create Account
            </NuxtLink>
          </div>
        </div>
      </Dialog>

      <!-- Events -->
      <div class="px-6 space-y-4 mb-16">
        <h3 class="text-lg font-semibold text-white">Featured Event</h3>
        <div v-if="community.events.length === 0">
          <p class="text-sm text-gray-600">No upcoming events.</p>
        </div>
        <div v-else>
          <BioFeatureEvent :event="community.events[0]" :community-name="community.name" />
        </div>
      </div>

      <!-- About The Community -->
      <div class="px-6 space-y-4 mb-16">
        <div class="bg-white rounded-xl p-4">
          <h3 class="text-lg font-semibold text-gray-800">Our Story</h3>
          <p class="text-sm text-gray-600 whitespace-pre-line pt-4">{{ community.description }}</p>
        </div>
      </div>


      <!-- People behind the Community -->
      <!-- <div class="px-6 space-y-4 mb-16">
        <div class="mb-4 text-center">
          <h2 class="text-2xl font-bold text-white">Meet the Team!</h2>
        </div>
        <ImageFeature :items="people" />
      </div>


      <div class="px-6 space-y-4 mb-16">
        <h2 class="text-lg font-semibold text-white">Gallery</h2>
        <GalleryGrid :images="galleryImages" />
      </div> -->

      <!-- Content Cards -->
      <!-- <div class="px-6 space-y-4">
        <div class="bg-white rounded-xl p-4 flex items-center space-x-4">
          <img src="https://placehold.co/50x50" alt="Ideas by mizan" class="w-12 h-12 rounded-full">
          <div>
            <h3 class="font-semibold">ideas by mizan</h3>
            <p class="text-sm text-gray-600">Straight-talking advice for developers. From coding to career growth, get
              the insights you need to thrive in tech.</p>
          </div>
        </div>
        <div class="bg-white rounded-xl p-4 flex items-center space-x-4">
          <div class="p-2 rounded-xl">
            <Icon name="lucide:instagram" class="h-8 w-8" />
          </div>
          <div>
            <h3 class="font-semibold">Contact us on our Instagram</h3>
            <p class="text-sm text-gray-600">Let's chat</p>
          </div>
        </div>
      </div> -->

      <!-- Footer -->
      <div class="mt-8 p-4 flex justify-center">
        <div class="text-xl font-bold text-white">powered by yally</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
const route = useRoute()
const toast = useToast()
const community = ref<CommunityWithEvents | null>(null)
const accessDescription = ref('')
const people = ref([
  {
    title: 'The Squeeze',
    handle: 'thesqueezepod',
    image: 'https://avatar.iran.liara.run/public'
  },
  {
    title: 'The Busyhead Project',
    handle: 'thebusyheadproject',
    image: 'https://avatar.iran.liara.run/public/girl'
  },
  {
    title: 'Lexi Hidalgo',
    handle: 'lexihidalgo',
    image: 'https://avatar.iran.liara.run/public'
  },
  {
    title: 'Stephen Curry',
    handle: 'stephencurry30',
    image: 'https://avatar.iran.liara.run/public/girl'
  }
])

const galleryImages = ref([
  {
    url: 'https://placehold.co/400x400',
    alt: 'Gallery image 1'
  },
  {
    url: 'https://placehold.co/400x400',
    alt: 'Gallery image 2'
  },
  {
    url: 'https://placehold.co/400x400',
    alt: 'Gallery image 3'
  },
  {
    url: 'https://placehold.co/400x400',
    alt: 'Gallery image 4'
  },
  {
    url: 'https://placehold.co/400x400',
    alt: 'Gallery image 5'
  },
  {
    url: 'https://placehold.co/400x400',
    alt: 'Gallery image 6'
  }
])

const isJoined = ref(false)
const isDialogVisible = ref(false)
const currentPath = computed(() => route.fullPath)

const closeDialog = () => {
  isDialogVisible.value = false
}

const handleJoinCommunity = () => {
  const { status } = useAuth()

  if (status.value !== 'authenticated') {
    isDialogVisible.value = true
  } else {
    joinCommunity()
  }
}

const fetchCommunityDetails = async () => {
  const config = useRuntimeConfig()
  const apiBaseUrl = config.public.apiBaseUrl
  const { token } = useAuth()

  try {
    const { data, error } = await useFetch<CommunityWithEvents>(`${apiBaseUrl}/communities/${route.params.slug}/bio`, {
      headers: {
        Authorization: token.value ? `${token.value}` : ''
      }
    })

    if (data.value) {
      community.value = data.value
      isJoined.value = data.value.joined || false
      accessDescription.value = data.value.access === 'public' ? 'Anyone can join this community.' : 'Only people you invite can join this community.'
    }

    if (error.value) {
      toast.add({ severity: 'error', summary: 'Error', detail: 'Failed to fetch community details', life: 3000 })
    }
  } catch (error) {
    console.error('Error fetching community details:', error)
    toast.add({ severity: 'error', summary: 'Error', detail: 'Failed to fetch community details', life: 3000 })
  }
}

fetchCommunityDetails()

const joinCommunity = async () => {
  const config = useRuntimeConfig()
  const apiBaseUrl = config.public.apiBaseUrl
  const { token } = useAuth()

  try {
    const { error } = await useFetch(`${apiBaseUrl}/communities/${community.value?.id}/join`, {
      method: 'POST',
      headers: {
        Authorization: `${token.value}`
      }
    })

    if (error.value) {
      toast.add({ severity: 'error', summary: 'Error', detail: 'Failed to join community', life: 3000 })
      return
    }

    isJoined.value = true
    toast.add({ severity: 'success', summary: 'Success', detail: 'Successfully joined community', life: 3000 })
  } catch (error) {
    console.error('Error joining community:', error)
    toast.add({ severity: 'error', summary: 'Error', detail: 'Failed to join community', life: 3000 })
  }
}

const { getImageUrl } = useImageUrl()

const handleShare = async () => {
  const shareData = {
    title: community.value?.name,
    text: community.value?.description,
    url: window.location.href
  }

  try {
    if (navigator.share) {
      await navigator.share(shareData)
    } else {
      // Fallback: Copy to clipboard
      await navigator.clipboard.writeText(window.location.href)
      toast.add({
        severity: 'success',
        summary: 'Link Copied',
        detail: 'Community link copied to clipboard',
        life: 3000
      })
    }
  } catch (error) {
    console.error('Error sharing:', error)
    toast.add({
      severity: 'error',
      summary: 'Error',
      detail: 'Failed to share community',
      life: 3000
    })
  }
}
</script>

<style></style>
