<template>
  <div>
    <h3 class="text-lg font-semibold text-white mb-2">
      <Icon name="lucide:calendar" class="mr-2" size="16" />
      {{ formatDate(event.startDateTime) }}
    </h3>
    <NuxtLink :to="`/events/${event.slug}/edit`" class="block">
      <div class="bg-white rounded-lg overflow-hidden p-4 mb-4">
        <div class="aspect-w-1 aspect-h-1 mb-4">
          <img v-if="event.imageUrl" :src="getImageUrl(event.imageUrl)" alt="Event image"
            class="w-full h-full object-cover rounded-lg" />
        </div>
        <h3 class="text-xl font-semibold text-gray-800 mb-1">{{ event.name }}</h3>
        <div class="flex items-center text-sm text-gray-600 mb-1">
          <Icon name="prime:clock" class="mr-1" size="16" />
          {{ formatTime(event.startDateTime) }}
        </div>
        <div class="flex items-center text-sm text-gray-600">
          <Icon name="prime:map" class="mr-1" size="16" />
          {{ event.location }}
        </div>
      </div>
    </NuxtLink>

    <button @click="handleRsvp" :disabled="isRsvped || isSubmitting" :class="[
      'w-full font-bold py-6 px-4 rounded-3xl shadow-lg transition duration-300 ease-in-out transform focus:outline-none focus:ring-2 focus:ring-opacity-50',
      isRsvped
        ? 'bg-green-600 hover:bg-green-600 cursor-default'
        : 'bg-blue-600 hover:bg-blue-700 hover:scale-105 focus:ring-blue-500'
    ]" class="text-white">
      <div class="flex items-center justify-center">
        <Icon :name="isRsvped ? 'lucide:check-circle' : 'lucide:calendar-plus'" class="mr-2" size="20" />
        <span v-if="isSubmitting">Confirming RSVP...</span>
        <span v-else-if="isRsvped">You're confirmed for this event!</span>
        <span v-else>RSVP Now - Don't Miss Out!</span>
      </div>
    </button>

    <!-- PrimeVue Dialog -->
    <Dialog v-model:visible="isDialogVisible" modal :style="{ width: '400px' }" :closable="true" :dismissableMask="true"
      header="Sign in to RSVP">
      <div class="p-4">
        <p class="mb-4 text-gray-600">
          Please sign in or create an account to RSVP for this event.
        </p>
        <div class="space-y-3">
          <NuxtLink :to="{ path: '/sign-in', query: { redirect: currentPath } }" @click="closeDialog"
            class="w-full bg-white text-gray-900 py-2 rounded-lg font-semibold hover:bg-gray-200 transition-colors flex items-center justify-center border border-gray-300">
            Sign In
          </NuxtLink>
          <NuxtLink :to="{ path: '/sign-up', query: { redirect: currentPath } }" @click="closeDialog"
            class="w-full bg-gray-700 text-white py-2 rounded-lg font-semibold hover:bg-gray-600 transition-colors flex items-center justify-center">
            Create Account
          </NuxtLink>
        </div>
      </div>
    </Dialog>
  </div>
</template>

<script setup lang="ts">
const { humanizeDate } = useHumanizeDate()
const toast = useToast()
const config = useRuntimeConfig()
const apiBaseUrl = config.public.apiBaseUrl

const { getImageUrl } = useImageUrl()

const props = defineProps({
  event: {
    type: Object as PropType<CommunityEvent>,
    required: true
  },
  communityName: {
    type: String,
    required: true
  }
})

const isRsvped = ref(false)
const isSubmitting = ref(false)
const isDialogVisible = ref(false)

const route = useRoute()
const currentPath = computed(() => route.fullPath)

const closeDialog = () => {
  isDialogVisible.value = false
}

// Check if user has already RSVPed when component mounts
const checkExistingRsvp = async () => {
  const { status, token } = useAuth()

  if (status.value === 'authenticated') {
    try {
      isRsvped.value = props.event.hasJoined || false
    } catch (error) {
      console.error('Error checking RSVP status:', error)
    }
  }
}

// Call on component mount
onMounted(() => {
  checkExistingRsvp()
})

const submitRsvp = async () => {
  const { token } = useAuth()
  isSubmitting.value = true

  try {
    await $fetch(`${apiBaseUrl}/events/${props.event.id}/participations`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${token.value}`
      }
    })

    isRsvped.value = true
    toast.add({
      severity: 'success',
      summary: 'RSVP Confirmed',
      detail: 'You\'re all set! We\'ll send you the event details shortly.',
      life: 3000
    })
  } catch (error) {
    console.error('Error submitting RSVP:', error)
    toast.add({
      severity: 'error',
      summary: 'RSVP Failed',
      detail: 'Unable to confirm your RSVP. Please try again.',
      life: 3000
    })
  } finally {
    isSubmitting.value = false
  }
}

const handleRsvp = () => {
  const { status } = useAuth()

  if (status.value !== 'authenticated') {
    isDialogVisible.value = true
  } else {
    submitRsvp()
  }
}

const formatDate = (dateString: string) => {
  const date = new Date(dateString)
  return humanizeDate(date)
}

const formatTime = (dateString: string) => {
  const date = new Date(dateString)
  return date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
}
</script>
