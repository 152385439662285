<template>
  <div v-if="href" class="flex items-center text-sm text-gray-500 mb-2">
    <a :href="ensureHttps(href)" target="_blank" rel="noopener noreferrer" class="text-blue-500 hover:underline">
      <Icon :name="icon" :size="size" />
    </a>
  </div>
</template>

<script setup lang="ts">
defineProps<{
  href: string | null
  icon: string
  size?: string
}>()

const ensureHttps = (url: string): string => {
  if (!url) return ''
  return url.startsWith('http://') || url.startsWith('https://') ? url : `https://${url}`
}
</script>