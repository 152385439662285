import { DAYS, MONTHS } from '@/utils/date';
/**
 * Composable for date humanization in Nuxt applications
 */
export function useHumanizeDate() {
  function humanizeDate(date: Date | string): string {
    // Convert input to Date object if string is provided
    const inputDate = date instanceof Date ? date : new Date(date);

    // Handle invalid dates
    if (isNaN(inputDate.getTime())) {
      throw new Error('Invalid date provided');
    }

    // Strip time component for comparison
    const strippedInput = new Date(inputDate.toDateString());
    const today = new Date(new Date().toDateString());

    // Calculate difference in days
    const diffTime = strippedInput.getTime() - today.getTime();
    const diffDays = diffTime / (1000 * 60 * 60 * 24);

    // Return relative or absolute date based on difference
    if (diffDays === 0) {
      return 'Today';
    } else if (diffDays === 1) {
      return 'Tomorrow';
    } else {
      return formatDate(inputDate);
    }
  }

  function formatDate(date: Date): string {
    const day = DAYS[date.getDay()];
    const month = MONTHS[date.getMonth()];
    const dateNum = date.getDate();
    const year = date.getFullYear();

    // Add appropriate suffix to date number
    const suffix = getDateSuffix(dateNum);

    // If date is in current year, omit year from output
    const currentYear = new Date().getFullYear();
    if (year === currentYear) {
      return `${day}, ${month} ${dateNum}${suffix}`;
    }

    return `${day}, ${month} ${dateNum}${suffix}, ${year}`;
  }

  function getDateSuffix(date: number): string {
    if (date >= 11 && date <= 13) {
      return 'th';
    }

    switch (date % 10) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  }

  return {
    humanizeDate,
    formatDate,
    getDateSuffix,
  };
}